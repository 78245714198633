










































































































































import {
  Vue, Component, Model, Prop, Watch,
} from 'vue-property-decorator';
import fuzzysort from 'fuzzysort';

@Component
export default class Dropdown extends Vue {
  @Model('change', { type: Array }) dropdownValues!: any

  @Prop({ type: Array, default: [] }) options!: any;

  @Prop({
    type: Object,
    default() {
      return {};
    },
  }) optionsSpeedDict?: any;

  @Prop({ type: Boolean, default: false }) multiselect!: boolean;

  @Prop({ type: Boolean, default: false }) showCloseBtn!: boolean;

  @Prop({ type: Boolean, default: false }) isFixed!: boolean;

  @Prop({ type: Boolean, default: false }) showSelectAllBtn!: boolean;

  @Prop({ type: Array, default: [] }) selectionLocation!: any;

  @Prop({ type: String, default: '' }) trackBy!: string;

  @Prop({ type: String, default: '' }) label!: string;

  //   @Prop({ type: String, default: 100 }) limit?: string;

	searchDropdownInput = ''

	fuzzysort_options: any[] = [];

	localTemp: any = []
	//   @Prop({ required: true }) stepItem!: any;

	created() {
		this.localTemp = JSON.parse(JSON.stringify(this.dropdownValues)).filter((o) => this.options.includes(o))
	  if (this.trackBy != '') {
	    for (let x = 0; x < this.options.length; x++) {
	      this.fuzzysort_options.push(this.options[x].text);
	    }
	  } else {
		  this.fuzzysort_options = JSON.parse(JSON.stringify(this.options));
	  }
	}

	toggleSelect() {
	  if (this.localTemp.length === this.options.length) {
	    this.localTemp = [];
	    this.$emit('change', []);
	  } else {
	    // this.dropdownValues = [];
	    this.options.filter((o) => !this.localTemp.includes(o)).forEach((element) => {
	      this.addValToDropdown(element);
	    });
	  }
	}

	isSelected(option) {
	  for (let x = 0; x < this.localTemp.length; x++) {
		  if (this.trackBy != '') {
	      if (option.text == this.localTemp[x].text) {
	        return true;
	      }
		  } else if (option == this.localTemp[x]) {
	      return true;
	    }
	  }
	  return false;
	}

	get filteredOptions() {
	  if (this.searchDropdownInput.length == 0) {
		  return this.options;
	  }

	  const results: any = [];
	  const fuzzysort_results = fuzzysort.go(this.searchDropdownInput, this.fuzzysort_options);
	  for (let x = 0; x < fuzzysort_results.length; x++) {
	    if (this.trackBy != '') {
	        if (Object.keys(this.optionsSpeedDict) && this.optionsSpeedDict[fuzzysort_results[x].target] != null) {
	          results.push(this.optionsSpeedDict[fuzzysort_results[x].target]);
	        } else {
	        for (let y = 0; y < this.options.length; y++) {
	          if (this.options[y].text == fuzzysort_results[x].target) {
	            results.push(this.options[y]);
	          }
	        }
	      }
	    } else {
	      // @ts-ignore
	      results.push(fuzzysort_results[x].target);
	    }
	  }
	  return results;
	}

	hideDropdown() {
	  this.$emit('confirm-dropdown');
	  this.$emit('hide-dropdown');
	}

	addValToDropdown(val) {
	//   const localTemp = JSON.parse(JSON.stringify(this.localTemp));
	  let did_emit = false;
	  if (val.disabled != null && val.disabled) {
		  return;
	  }
	  if (!this.multiselect) {
		  while (this.localTemp.length) { this.localTemp.pop(); }
		  this.localTemp.push(val);
		  did_emit = true;
		  this.$emit('change', [val]);
	  } else {
	    let contains_val = -1;
		  for (let t = 0; t < this.localTemp.length; t++) {
			 	  if (this.trackBy !== '') {
			  if (this.localTemp[t].value == val.value) {
				  contains_val = t;
				  break;
			  }
				  } else if (this.localTemp[t] == val) {
				  contains_val = t;
				  break;
			  }
		  }
	    if (contains_val != -1) {
	      const index = contains_val;
	      if (index > -1) {
	        this.localTemp.splice(index, 1);
	      }
	    } else {
	      this.localTemp.push(val);
	    }
	  }

	  if (!did_emit) {
		  this.$emit('change', this.localTemp);
	    //   this.localTemp = localTemp;
	  }
	}

	@Watch('dropdownValues')
	onDropdownValuesUpdate() {
	  this.localTemp = JSON.parse(JSON.stringify(this.dropdownValues));
	}
}
